(function(wnd) {
    function FranchiseJoinForm(form, params) {
        try {
            if (!form || (form && form.length == 0)) {
                throw 'Form is required!';
            }

            if (!form.is('form')) {
                throw 'Form must be a form html element!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    FranchiseJoinForm.prototype = {
        form: undefined,
        button: undefined,
        sendedForm: false,
        notyf: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find('[data-purpose="form-send-button"]');

            this.notyf = new Notyf({
                delay:4000,
                alertIcon: 'notyf-error-icon',
                confirmIcon: 'notyf-confirm-icon'
            });
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.button && this.button.length > 0) {
                this.button.click(function() {
                    _self.sendForm();
                });
            }

            this.form.on('submit', function(e){
                e.preventDefault();
                _self.sendForm();
            });
        },
        setParams: function(params) {

        },
        sendForm: function() {
            var _self = this;
            
            if (!this.sendedForm) {
                var data = {
                    'lang': frontend_lang
                };

                $.each(this.form.serializeArray(), function(key, item) {
                    data[item.name] = item.value;
                });
                 
                $.ajax({
                    url:this.form.attr('action'),
                    type:'post',
                    dataType:'json',
                    data:data,
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(resp) {
                        if (resp.success) {
                            if (typeof gtag === 'function') {
                                gtag('event', 'Jelentkezés', {
                                    'event_category': 'Lead',
                                    'event_action': 'Sikeres jelentkezés',
                                    'event_label': data.name
                                });
                            }

                            _self.resetForm();
                            if (resp.msg) {
                                _self.notyf.confirm(resp.msg);
                            }
                        } else {
                            if (typeof resp.msg !== 'undefined' && (resp.msg).trim()) {
                                _self.notyf.alert(resp.msg);
                            }
                            if (typeof resp.errors !== 'undefined') {
                                
                                $.each(resp.errors, function(key, text) {
                                    var field = _self.form.find('[name="'+key+'"]');
                                    if (field && field.length > 0) {
                                        field.addClass('has-error');
                                    }
                                    if (text.trim()) {
                                        _self.notyf.alert(text);
                                    }
                                });
                            }
                        }
                    }
                })
            }
        },
        resetForm: function() {
            $('[data-remodal-id="joinForm"]').remodal().close();
            this.form.find('input[type="text"]').val('');
            this.form.find('input[type="checkbox"]').prop('checked', false);
        }
    };

    wnd.FranchiseJoinForm = FranchiseJoinForm;
})(window);
